export const ENTRY_COLUMN = [
    {
        title: '发票号码',
        isTrue: true
    },
    {
        title: '发票代码',
        isTrue: true
    },
    {
        title: '数电票号',
        isTrue: true
    },
    {
        title: '发票类型',
        isTrue: true
    },
    {
        title: '入账编号',
        isTrue: true
    },
    {
        title: '发票金额',
        isTrue: true
    },
    {
        title: '发票税额',
        isTrue: true
    },
    {
        title: '发票状态',
        isTrue: true
    },
    {
        title: '销方名称',
        isTrue: true
    },
    {
        title: '购方名称',
        isTrue: true
    },
    {
        title: '开票日期',
        isTrue: true
    },
    {
        title: '处理状态',
        isTrue: true
    },
    {
        title: '处理结果',
        isTrue: true
    },
    {
        title: '提交人员',
        isTrue: true
    },
    {
        title: '提交时间',
        isTrue: true
    },
    {
        title: '入账状态',
        isTrue: true
    },
    {
        title: '入账属期',
        isTrue: true
    },
    {
        title: '入账时间',
        isTrue: true
    },
    {
        title: '同步编号',
        isTrue: true
    },
    {
        title: '数据类型',
        isTrue: true
    },
    {
        title: '同步起始',
        isTrue: true
    },
    {
        title: '同步终止',
        isTrue: true
    },
]

