<template>
  <el-drawer append-to-body :with-header="false" :visible.sync="visible" :show="show"
             @close="$emit('update:show', false)" size="1000px">
    <div class="open-invoice-details">
      <el-tabs v-model="activeName">
        <el-tab-pane label="入账详情" name="first">
          <div class="invoice-middle">
            <span class="middle-average">
              <el-row>
                <el-col :span="12">
                  <label style="min-width: 42px">入账来源:</label>
                  <span style="padding: 0 10px;">{{ entryObj.source === 0 ? '本地' : '局端' }}</span>
                </el-col>
                <el-col :span="12">
                  <label style="min-width: 42px">提交人:</label>
                  <span style="padding: 0 10px;">{{ entryObj.creatorName }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <label style="min-width: 42px">数据类型:</label>
                  <span style="padding: 0 10px;">进项票</span>
                </el-col>
                <el-col :span="12">
                  <label style="min-width: 42px">提交入账时间:</label>
                  <span style="padding: 0 10px;">{{ entryObj.submitTime }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <label style="min-width: 42px">处理状态:</label>
                  <span style="padding: 0 10px;">{{ hStatus }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <label style="min-width: 42px">同步开始时间:</label>
                  <span style="padding: 0 10px;">{{ entryObj.syncStart }}</span>
                </el-col>
                <el-col :span="12">
                  <label style="min-width: 42px">入账时间:</label>
                  <span style="padding: 0 10px;">{{ entryObj.entryTime }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <label style="min-width: 42px">同步结束时间:</label>
                  <span style="padding: 0 10px;">{{ entryObj.syncEnd }}</span>
                </el-col>
                <el-col :span="12">
                  <label style="min-width: 42px">处理结果:</label>
                  <span style="padding: 0 10px;">{{ entryObj.handlerResult }}</span>
                </el-col>
              </el-row>
            </span>

          </div>

          <div class="invoice-bottom">
            <el-row type="flex" justify="space-between">
              <el-col>
                <!--                <span>开票明细</span>-->
              </el-col>
            </el-row>
            <div class="bottom-table">
              <el-table :data="record" border stripe
                        :header-cell-style="handleHeaderCellStyle" max-height="280">
                <el-table-column
                    label="序号"
                    type="index"
                    width="50"
                    align="center">
                </el-table-column>
                <el-table-column prop="sellerName" label="销方名称" width="240"/>
                <el-table-column prop="sellerTaxNo" label="销方税号" width="240"/>
                <el-table-column prop="invoiceType" label="发票类型" :formatter="typeFormatter" width="180"/>
                <el-table-column prop="invoiceTime" label="开票日期" width="240"/>
                <el-table-column prop="invoiceCode" label="发票代码" width="240"/>
                <el-table-column prop="invoiceNo" label="发票号码" width="240"/>
                <el-table-column prop="amount" label="金额"/>
                <el-table-column prop="taxAmount" label="税额"/>
                <el-table-column prop="invoiceStatus" label="发票状态" :formatter="renderStatus"/>
                <el-table-column prop="buyerName" label="购方名称" width="240"/>
                <el-table-column prop="buyerTaxNo" label="购方税号" width="240"/>
                <el-table-column prop="entryPeriod" label="入账属期" width="140"/>

              </el-table>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-drawer>
</template>

<script>
import {changeMethods, tableStyle} from '@/util/mixins';

export default {
  mixins: [tableStyle, changeMethods],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    entryObj: Object
  },
  data() {
    return {
      hStatus: '',
      record: [],
      isShowName: false,
      isShowCode: false,
      visible: this.show,
      activeName: 'first',
      equBillingMode: []
    };
  },
  created() {
  },
  watch: {
    show() {
      this.visible = this.show;
    }
  },
  computed: {},
  mounted() {
    if (this.entryObj) {
      switch (this.entryObj.handlerStatus) {
        case "0":
          this.hStatus = '处理中'
          break
        case "1":
          this.hStatus = '已处理'
          break
        case "2":
          this.hStatus = '处理失败'
          break
        case  "3":
          this.hStatus = '同步中'
          break
        case "4":
          this.hStatus = '同步成功'
          break
        case "5":
          this.hStatus = '同步失败'
          break
        default:
      }
      this.record.push(this.entryObj)
    }
  },
  methods: {
    renderStatus(row, col) {
      switch (row.invoiceStatus) {
        case "0":
          return "正常";
        case "2":
          return "作废";
        case "3":
          return "红字（一次性冲红）";
        case "7":
          return "已部分冲红";
        case "8":
          return "已全额冲红";
        default:
          return "";
      }

    },
    showMessage(message) {
      if (message == '' || message == undefined || message == null) {
        return '--';
      }
      return message;
    },
    typeFormatter(row, col) {
      switch (row.invoiceType) {
        case "31":
        case "81":
          return "电子发票（增值税专用发票）";
        case "82":
          return "电子发票（普通发票）";
        case "17":
          return "海关税增值税发票";
        case "14":
          return "通行费电子发票";
        case "08":
          return "增值税电子专用发票";
        case "03":
          return "机动车发票";
        case "01":
          return "增值税专用发票";
        default:
          return "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.open-invoice-details {
  margin: 0 24px;

  ::v-deep .el-tabs__nav {
    margin: 0;
  }

  .invoice-top {
    display: flex;
    margin-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #cccccc;

    .label-span {
      margin-right: 86px;
    }
  }

  .invoice-middle {
    display: flex;
    //margin-top: 32px;
    //padding-bottom: 16px;
    //border-bottom: 1px solid #cccccc;

    .middle-average {
      width: 900px;

      .first-row {
        margin-bottom: 16px;

        span {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
        }
      }

      .second-row {
        display: flex;
        margin-bottom: 16px;

        label {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-right: 4px;
        }

        div {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          text-align: left;
          margin-right: 20px;
        }
      }

      .other-row {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        margin-right: 20px;

        label {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-right: 4px;
        }

        span {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }

  .invoice-bottom {
    margin-top: 32px;
    height: calc(100% - 225px);
    //border-bottom: 1px solid #cccccc;
    padding-bottom: 32px;

    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }

    .bottom-table {
      margin-top: 16px;
    }
  }

  .invoice-remark {
    margin-top: 24px;

    .remark-title {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      margin-bottom: 16px;
    }

    .remark-content {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 24px;
      border-bottom: 1px solid #cccccc;
    }
  }

  .open-invoice-message {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin-bottom: 24px;
    display: flex;

    .one-col {
      width: 400px;

      .second-row {
        display: flex;
        margin-bottom: 16px;

        label {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-right: 4px;
        }

        div {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          text-align: left;
          margin-right: 20px;
        }
      }
    }
  }
}

.el-row {
  margin-bottom: 16px;

  &:first-child {
    margin-top: 32px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .label-span {
    span {
      margin-left: 8px;
    }
  }
}

::v-deep .el-drawer__body {
  overflow: auto;
}
</style>
